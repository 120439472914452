import React, { useState } from "react";
import { Auth,API } from "aws-amplify";
import Form from "react-bootstrap/Form";
 
import LoaderButton from "../components/LoaderButton";
import { useAppContext } from "../lib/contextLib";
import { useFormFields } from "../lib/hooksLib";
import { onError } from "../lib/errorLib";
import { useHistory } from "react-router-dom";
import Logo from '../images/-logos_blue.png';  //  src/images
import "./Login.css";

export default function Login() {
 
  const { userHasAuthenticated } = useAppContext();
  const {setUserOrg} = useAppContext();  
  const {setcurrentOrgLogo,setcurrentOrgObj} = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [errorFound,seterrorFound] = useState(false);
  const [dispMsg,setdispMsg] = useState("");
  const history = useHistory();
  setcurrentOrgLogo(Logo);

  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: ""
  });

  function validateForm() {
    return fields.email.length > 0 && fields.password.length > 0;
  }

  const DisplayInfo = () => (
    <div className="pt-5">
       
            <div id="dispInfo" className="alert alert-danger   padding-lg " role="alert">
                <span>{dispMsg}</span>
            </div>
           
        
    </div>
);

  async function handleSubmit(event) {
    seterrorFound(false);
    event.preventDefault();

    setIsLoading(true);
    //console.log("Pressed login");

    try {
      await Auth.signIn(fields.email, fields.password);
      

      try{
        var userInfo = await Auth.currentUserInfo();
  
      }catch(e){
        console.log(e);
      }
      
      //console.log("USER IFNO:",userInfo);
      var orgName = userInfo.attributes['custom:orgDomain'];
      var Orgs = await API.get("mygiving", `/domain/${orgName}`);
      

      if (orgName==="mygiving.io")
      {
        userHasAuthenticated(true);
        history.push("/superuser");
      }else{
        setUserOrg(Orgs[0].orgPath);
        userHasAuthenticated(true);
        setcurrentOrgObj(Orgs[0]);
        history.push("/org/"+Orgs[0].orgPath+"/admin/campaign");
        setcurrentOrgLogo(Orgs[0].orgBannerLogo);
  

      }

       
    } catch (e) {
      onError(e);
      setIsLoading(false);
      setdispMsg(e.message);
      seterrorFound(true);
    }
  }

  return (
    <div className="Login">
      <Form onSubmit={handleSubmit}>
        <Form.Group size="lg" controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            autoFocus
            type="email"
            value={fields.email}
            onChange={handleFieldChange}
          />
        </Form.Group>
        <Form.Group size="lg" controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            value={fields.password}
            onChange={handleFieldChange}
          />
        </Form.Group>
        <LoaderButton 
          block
          size="lg"
          type="submit"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Login
        </LoaderButton>
      </Form>

      {errorFound?<DisplayInfo/>:null}
    </div>
  );
}