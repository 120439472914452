
import { Button, Card, Row, Col } from "react-bootstrap";
import React, { useState, useEffect } from "react";
//import { useAppContext } from "../lib/contextLib";
import { onError } from "../lib/errorLib";
import { Link } from "react-router-dom";
import { API } from "aws-amplify";
import "./Home.css";
//import flowChart from '../images/flowChart.jpg';  //  src/images
//import nftSearch from '../images/nftSearch.jpg';  //  src/images
//import cryptoEngagement from '../images/cryptoEngagement.jpg';  //  src/images
//import cryptoDonation from "../images/cryptoDonation.jpg"
import introVideo from '../videos/mygivingIntro.mp4';
import mygivingVideoPoster from '../images/mygivingVideoPoster.png'
import { PopupButton } from "react-calendly";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { FacebookIcon, LinkedinIcon, TwitterIcon, EmailIcon } from "react-share";

export default function Home() {

  const [orgs, setorgs] = useState([]);

  //const { userOrg } = useAppContext();


  const [isLoading, setIsLoading] = useState(true);
  //console.log("ORGGGGGGG", userOrg);
  useEffect(() => {

    async function onLoad() {

      try {
        var orgs = await loadOrgs();
        //console.log("org" + orgs);
        for (var i = 0; i <= orgs.length - 1; i++) {
          if (orgs[i].imageURL) {
            //nfts[i].imageURL = await Storage.vault.get(orgs[i].imageURL);
          }

        }
        //nfts[4].imageURL = await Storage.vault.get(nfts[4].imageURL);
        setorgs(orgs);

      } catch (e) {
        onError("homeonl" + e);
      }

      setIsLoading(false);
      return;

    }

    onLoad();
  }, []);


  function loadOrgs() {
    return API.get("mygiving", "/organizations");
  }




  function renderOrgList(orgs) {
    return (
      <>


        <section className="bg-light">
          <h3 className="fw-bold mt-2"> Explore our collections </h3>
          <br />
          <Row xs={1} className="g-4 mx-auto justify-content-md-center">
            {orgs.map(({ sk, orgName, orgBannerLogo }) => (
              <Col key={sk}>
                <Card className="HomeOrgListItem-main-card mx-auto"  >
                  <Card.Body>
                    <div className="imageHolder mx-auto">
                      <Card.Img className="HomeOrgListItem-img mx-auto justify-content-md-center" src={orgBannerLogo} />
                    </div>
                    <Card.Title className="wrapper text-wrap fw-bold">{orgName}</Card.Title>
                  </Card.Body>
                  <Card.Footer className="mx-auto justify-content-md-center justify-content-center">
                    <Link to={`/org/${sk.split("#")[1]}`}>
                      <Button variant="primary">Browse</Button>
                    </Link>
                  </Card.Footer>
                </Card>
              </Col>
            ))}
          </Row>
        </section>
      </>
    );
  }

  function renderLander() {
    return (
      <div className="lander">
        <section className="  sectionpad">
          <div className="container">

            <div className="row">
              <div className="col-lg sectionpad2 ">
                <h2 className="fw-bold">Gamify Philanthropy with NFTs</h2><br />
                MyGiving gamifies philanthropy by empowering nonprofits to create and send NFTs and digital assets while providing a way for donors
                to virtually share and celebrate the organizations they support.
                <div className="pt-5 mb-4">
                  <PopupButton className="btn btn-primary" text="Schedule a demo to hear more!" url="https://calendly.com/mygiving2/30min" ></PopupButton>
                </div>
                <div className="mt-3">
                  <Link to={`/walletboard`}>
                    <h6>Leaderboard</h6>
                  </Link>
                </div>
              </div>
              <div className="col-lg sectionpad2 hght">
                <div className='player-wrapper'>

                  <video className="react-player" controls poster={mygivingVideoPoster}>
                    <source src={introVideo} type="video/mp4" auto />
                    Your browser does not support the video tag.
                  </video>
                  <div className="mt-1">
                    <Link to={`/faq-page`}>
                      <h6>FAQ</h6>
                    </Link>
                  </div>
                </div>

              </div>

            </div>


          </div>
        </section>
        <section className="  sectionpad ">
          {!isLoading && renderOrgList(orgs)}
        </section>

        <section className=" sectionpad">
          <h3 className="fw-bold"> Featured NFTs </h3>
          <div className="container">

            <CarouselProvider
              naturalSlideWidth={20}
              naturalSlideHeight={10}
              totalSlides={5}
              interval={5000}
              isPlaying={true}
              isIntrinsicHeight={true}

            >
              <Slider>
                <Slide index={0}>
                  <div className="row">
                    <div className="col-lg sectionpad2">
                      <a href="https://mygiving.io/org/WILD/nfts/a89e8b80-4cf3-11ed-8124-8dddbf3da605">
                        <img src='https://nfts.mygiving.io/public/1665884485693-WILD_NFT.png' className="img-fluid2" alt="flowchart"></img>
                      </a>
                    </div>
                    <div className="col-lg sectionpad2 ">
                      <h2 className="fw-bold">WILD Community Card</h2><br />
                      The Community Card represents your membership in a unique community of NFT-collecting nature advocates who invested in WILD’s critical work mobilizing support to protect Half of Earth’s lands and seas. Thank you for making an infinite future possible for all life on Earth!
                    </div>
                  </div>
                </Slide>
                <Slide index={1}>
                  <div className="row">
                    <div className="col-lg sectionpad2">
                      <a href="https://mygiving.io/org/WILD/nfts/9257b4e0-516d-11ed-bfce-3fdef60312a5">
                        <img src='https://nfts.mygiving.io/public/1666376648565-WILD_NFT.png' className="img-fluid2" alt="cryptoDonation"></img>
                      </a>
                    </div>
                    <div className="col-lg sectionpad2 ">
                      <h3 className="fw-bold">Regulation Five: Predation</h3><br />
                      Tooth, claw, maw, growl. These are the implements of ecological balance. These are the weapons of hunger. A shadow across the sun, a rustle in the tall grass. Grazers on alert. The chase. Population traffic cops come in many forms – silvery scales above a bright coral reef, eight legs lurking on a silken web – all of them lethal. Their primal duty a consequence of an authentic need – an empty belly – its vital importance to people and nature undiminished by the officer’s selfish motivation. Wheat grows to harvest because of predators, plague falters in its spread because of predators, ecosystems in warm tropical reefs and northern pine forests flourish because of the predators who spare the world from the depravations of too many vegetarians. When giving thanks to nature, remember the predators, the hungry shadows whose pursuit of their next meal brings balance to the world.
                    </div>

                  </div>
                </Slide>                
                <Slide index={2}>
                  <div className="row">
                    <div className="col-lg sectionpad2">
                      <a href="https://mygiving.io/org/WILD/nfts/b41016e0-53b6-11ed-95dd-31278594d9eb">
                        <img src='https://nfts.mygiving.io/public/1666627948043-WILD_NFT.png' className="img-fluid2" alt="nftSearch"></img>
                      </a>
                    </div>
                    <div className="col-lg sectionpad2 ">
                      <h3 className="fw-bold">Provision Six: Philanthropists</h3><br />
                      Taking is the norm, especially from nature. Just ask most economists and quite a few politicians. Not only will they likely agree with this statement, but they will probably proudly defend it as a sign of their virtue, evidence that they prioritize human well-being (or at least, the well-being of their constituencies). Philanthropists who support wilderness conservation are turning such arguments on their head, demonstrating that not only can we give back to nature, but doing so is as beneficial for people as it is for wildlife. As we consider what service people can offer the planet, philanthropists who give generously according to their means (whether that’s $5 or $5 million) offer a powerful behavioral model and an opportunity to discover the transformational potential of generosity.
                    </div>
                  </div>
                </Slide>
                <Slide index={3}>
                  <div className="row">
                    <div className="col-lg sectionpad2">
                      <a href="https://mygiving.io/org/WILD/nfts/99fa1060-53bd-11ed-9964-2d0d21543cdd">
                        <img src='https://nfts.mygiving.io/public/1666630909828-WILD_NFT.png' className="img-fluid2" alt="cryptoEngagement"></img>
                      </a>
                    </div>
                    <div className="col-lg sectionpad2 ">
                      <h3 className="fw-bold">Support Three: Collaboration</h3><br />
                      Sacred Strides <a href="https://www.youtube.com/watch?v=G5tzF5rjeXU" target="_blank"  rel="noreferrer">0:48-1:01</a> “You guys are entering that sacred time, when now you have to call upon something bigger than you.” ~ Regina Lopez-Whiteskunk, Ute Mountain Ute Tribe
                    </div>
                  </div>
                </Slide>
                <Slide index={4}>
                  <div className="row">
                    <div className="col-lg sectionpad2">
                      <a href="https://mygiving.io/org/WILD/nfts/4c11ddb0-53c7-11ed-becd-c59bd2dee378">
                        <img src='https://nfts.mygiving.io/public/1666635076930-WILD_NFT.png' className="img-fluid2" alt="cryptoEngagement"></img>
                      </a>
                    </div>
                    <div className="col-lg sectionpad2 ">
                      <h3 className="fw-bold">Culture Five: Recreation</h3><br />
                      Imagine a conifer-rimmed alpine lake stolen whole from your fondest summer memory. Extravagant sunlight glitters over walleye-tail ripples, intermittently blinding you with hot impressions of a silk blue sky and deep forest shadow. Every day of this summer begins with the earthy-clean fragrance of a mountain morning: pine resin and campfire smoke. Your heart beats excitedly, impatiently, in your chest, restless for adventure. Wild nature is the destination of our memories, dreams, and adventures. Within its woodlands and shorelines we find a respite from routine and an assurance that somewhere outside the hustling din of commutes and offices, tranquility reigns. When we protect wilderness, we protect so more than just the place, we protect human experience within the place. We protect our own ability to return, time and again, to the wild.
                    </div>
                  </div>
                </Slide>                
              </Slider>
              <ButtonBack className="btn-primary mr-5">{"<"}</ButtonBack>
              <ButtonNext className="btn-primary ml-5">{">"}</ButtonNext>

            </CarouselProvider>
          </div>



        </section>



        <section className="  sectionpad">
          <div>
            <h3 className="fw-bold"> What we do?</h3>
            <br />
            <div className="row justify-content-md-center">


              <div className="col-sm-6 col-lg-4 mb-3">
                <h6>NFT/FT Platform</h6>
                <p className="text-muted">One stop shop to create, manage and transfer your tokens</p>
              </div>

              <div className="col-sm-6 col-lg-4 mb-3">
                <h6>NFT/FT Structuring</h6>
                <p className="text-muted">20+ years of fund raising experience to structure your NFT token tiers</p>
              </div>

              <div className="col-sm-6 col-lg-4 mb-3">
                <h6>NFT/FT creation</h6>
                <p className="text-muted">Excellent artists to help you with the creation of your token images</p>
              </div>

              <div className="col-sm-6 col-lg-4 mb-3">
                <h6>Blockchain Fundamentals</h6>
                <p className="text-muted">Blockchain education from wallets to chains to Extensions</p>
              </div>
            </div>

          </div>
        </section >

        {footerSec()}

      </div >
    );
  }

  function footerSec() {
    return (
      <section className="bg-light">
        <div className="py-5  ">
          <div className="container">
            <div className="row row-cols-auto">
              <div className="col">
                <Link to={`/faq-page`}>
                  <h5>FAQ</h5>
                </Link>
                <ul className="contact-info list-unstyled">

                </ul>
              </div>
              <div className="col">
                <Link to={`/resources`}>
                  <h5>Resources</h5>
                </Link>
                <ul className="links list-unstyled">

                </ul>
              </div>
              <div className="col">
                <Link to={`/legal`}>
                  <h5>Legal</h5>
                </Link>
                <ul className="links list-unstyled">

                </ul>
              </div>
              <div className="col">
                <Link to={`/contactus`}>
                  <h5>Contact Us</h5>
                </Link>

                <ul className="links list-unstyled">

                </ul>
              </div>
              <div className="col">
                <Link to={`/aboutus`}>
                  <h5>About Us</h5>
                </Link>

                <ul className="links list-unstyled">

                </ul>
              </div>
            </div>
            <div class="container">
              <div class="row row-cols-auto">
                <div class="col"><a href="https://www.facebook.com/mygiving.io" rel="noopener noreferrer" target="_blank" ><FacebookIcon round size={32} /></a></div>
                <div class="col"><a href="https://twitter.com/MyGivingio" rel="noopener noreferrer" target="_blank"><TwitterIcon round size={32} /></a></div>
                <div class="col"><a href="https://www.linkedin.com/company/80964223" rel="noopener noreferrer" target="_blank" ><LinkedinIcon round size={32} /></a></div>
                <div class="col"><a href="mailto:hello@mygiving.io"><EmailIcon round size={32} /></a></div>
              </div>
            </div>


          </div>
        </div>
        <div className="py-3 bg-dark text-white">
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-md-7 text-center  ">
                <p className="mb-md-0">(C) 2021 mygiving Inc. All rights reserved. </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }



  return (
    <div className="Home">

      {renderLander()}
    </div>
  );
}