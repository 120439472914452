import React, { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { API } from "aws-amplify";
import { onError } from "../lib/errorLib";
import { useAppContext } from "../lib/contextLib";
import Form from "react-bootstrap/Form";
import LoaderButton from "../components/LoaderButton";
import config from "../config";
import { s3Upload } from "../lib/awsLib";
import { SketchPicker } from "react-color";
import Footer from "./Footer";
import "./Settings.css";
import { stateFromHTML } from "draft-js-import-html";
import RichTextEditor from "../components/RichTextEditor"

export default function Settings() {
  const bannerFile = useRef(null);
  const logoFile = useRef(null);
  const history = useHistory();
  const [org, setorg] = useState(null);

  const [orgWelcomeMessage, setorgWelcomeMessage] = useState("");
  const [orgMissionStatement, setorgMissionStatement] = useState("");
  const [orgWelcomeMessageHeader, setorgWelcomeMessageHeader] = useState("");

  const [orgEmailText, setorgEmailText] = useState("");
  const [orgEmailSubject, setorgEmailSubject] = useState("");
  const [orgWebsite, setorgWebsite] = useState("");
  const [orgdonateURL, setorgdonateURL] = useState("");
  const [orgKey, setorgKey] = useState(true);
  const [orgTaxID, setorgTaxID] = useState("");
  const [orgLocation, setorgLocation] = useState("");
  const [orgType, setorgType] = useState("");
  const [orgEmail, setorgEmail] = useState("");
  const [pk, setpk] = useState("");

  const [orgName, setorgName] = useState("");
  const { userOrg, setcurrentOrgObj, setcurrentOrgLogo } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(true);

  const [orgColorTheme, setorgColorTheme] = useState("");

  const [htmlContent, setHTMLContent] = useState("");
  const onSwitchAction = () => {
    setorgKey(!orgKey);
  };

  useEffect(() => {
    function loadOrg() {
      return API.get("mygiving", `/organization/${userOrg}`);
    }

    async function onLoad() {
      try {
        const org = await loadOrg();
        setcurrentOrgObj(org);
        setcurrentOrgLogo(org.orgBannerLogo);
        //console.log(JSON.stringify(org, 2));
        const {
          pk,
          orgName,
          orgWelcomeMessage,
          orgEmailText,
          orgEmailSubject,
          orgEmail,
          orgColorTheme,
          orgWebsite,
          orgdonateURL,
          orgType,
          orgTaxID,
          orgLocation,
          orgWelcomeMessageHeader,
          orgMissionStatement,
          orgKey,
        } = org;
        setorgName(orgName);
        setpk(pk);
        setorg(org);
        setorgWelcomeMessage(orgWelcomeMessage);
        setorgEmail(orgEmail);
        setorgWebsite(orgWebsite);
        setorgdonateURL(orgdonateURL);
        setorgTaxID(orgTaxID);
        setorgLocation(orgLocation);
        setorgType(orgType);
        setorgEmailSubject(orgEmailSubject);
        setorgKey(orgKey);
        setorgWelcomeMessageHeader(orgWelcomeMessageHeader);
        setorgMissionStatement(orgMissionStatement);
        // setorgBannerImage(orgBannerImage);
        // setorgBannerLogo(orgBannerLogo);
        setorgColorTheme(orgColorTheme);
        //console.log(JSON.stringify(org, 2));
        let contentState = stateFromHTML(orgEmailText);
        setHTMLContent(contentState);
        setorgEmailText(orgEmailText);
        setIsLoading2(false);
      } catch (e) {
        onError(e);
      }
    }

    onLoad();
  }, [userOrg, setcurrentOrgObj, setcurrentOrgLogo]);

  function validateForm() {
    return (
      orgName &&
      orgWelcomeMessage &&
      orgEmailText &&
      orgEmail &&
      orgWebsite &&
      orgdonateURL &&
      orgTaxID &&
      orgLocation &&
      orgEmailSubject &&
      orgWelcomeMessageHeader &&
      orgMissionStatement &&
      orgType
    );
  }

  function handleChangeComplete(color) {
    setorgColorTheme(color.hex);
    console.log(color.hex);
  }

  function formatFilename(str) {
    //return userOrg+"_banner"+str.split(".")[1];
    return str.replace(/^\w+-/, "");
  }

  function handleFileChangeBanner(event) {
    bannerFile.current = event.target.files[0];
  }

  function handleFileChangeLogo(event) {
    logoFile.current = event.target.files[0];
  }

  async function updateOrg(orgUpd) {
    //console.log("about to update" + JSON.stringify(orgUpd, 2));
    return API.put("mygiving", `/organization/${pk}`, {
      body: orgUpd,
    });
  }

  const handleEditorChange = (data) => {
    setorgEmailText(data);
  };

  async function handleSubmit(event) {
    setIsLoading(true);

    event.preventDefault();

    if (bannerFile.current) {
      if (
        bannerFile.current &&
        bannerFile.current.size > config.MAX_ATTACHMENT_SIZE
      ) {
        alert(
          `Please pick a file smaller than ${
            config.MAX_ATTACHMENT_SIZE / 1000000
          } MB.`
        );
        return;
      }
      const bannerKey =
        userOrg + "-banner." + bannerFile.current.name.split(".")[1];
      const bannerURL = bannerFile.current
        ? await s3Upload(bannerFile.current, bannerKey)
        : null;
      //console.log(config.imageURL + bannerURL);
      var orgBannerImage = config.imageURL + bannerURL;
    } else {
      orgBannerImage = org.orgBannerImage;
    }

    if (logoFile.current) {
      if (
        logoFile.current &&
        logoFile.current.size > config.MAX_ATTACHMENT_SIZE
      ) {
        alert(
          `Please pick a file smaller than ${
            config.MAX_ATTACHMENT_SIZE / 1000000
          } MB.`
        );
        return;
      }
      const logoKey = userOrg + "-logo." + logoFile.current.name.split(".")[1];
      const logoURL = logoFile.current
        ? await s3Upload(logoFile.current, logoKey)
        : null;
      //console.log(logoURL);
      // setorgBannerLogo("https://" + config.imageURL + "/" + logoURL);
      var orgBannerLogo = config.imageURL + logoURL;
      //console.log("orgBannerImage:"+orgBannerImage,"logoURL:"+logoURL);
    } else {
      orgBannerLogo = org.orgBannerLogo;
    }

    await updateOrg({
      orgName,
      orgWelcomeMessage,
      orgBannerImage,
      orgBannerLogo,
      orgEmailText,
      orgEmail,
      orgColorTheme,
      orgWebsite,
      orgdonateURL,
      orgTaxID,
      orgLocation,
      orgType,
      orgEmailSubject,
      orgMissionStatement,
      orgKey,
      orgWelcomeMessageHeader,
    });

    setIsLoading(false);
    history.push("/org/" + org.orgPath + "/admin/campaign");
  }

  return (
    <div>
      <div className="shadow-lg p-3 mb-5 bg-white rounded CreateNFT px-2 mt-4">
        {org && (
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mt-3" controlId="orgName">
              <Form.Label className="fw-bold">Organization Name</Form.Label>
              <Form.Control
                as="textarea"
                value={orgName}
                onChange={(e) => setorgName(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mt-3" controlId="orgWelcomeMessageHeader">
              <Form.Label className="fw-bold">
                Banner Welcome Message Header Text
              </Form.Label>
              <Form.Control
                as="textarea"
                value={orgWelcomeMessageHeader}
                onChange={(e) => setorgWelcomeMessageHeader(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mt-3" controlId="orgWelcomeMessage">
              <Form.Label className="fw-bold">
                Banner Welcome Message
              </Form.Label>
              <Form.Control
                as="textarea"
                value={orgWelcomeMessage}
                onChange={(e) => setorgWelcomeMessage(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mt-3" controlId="orgMissionStatement">
              <Form.Label className="fw-bold">Mission Statement</Form.Label>
              <Form.Control
                as="textarea"
                value={orgMissionStatement}
                onChange={(e) => setorgMissionStatement(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mt-3" controlId="orgEmailSubject">
              <Form.Label className="fw-bold">
                Email NFT claim Subject
              </Form.Label>
              <Form.Control
                as="textarea"
                value={orgEmailSubject}
                onChange={(e) => setorgEmailSubject(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mt-3" controlId="orgEmailText">
              <Form.Label className="fw-bold">Email NFT claim Text</Form.Label>
              {htmlContent && <RichTextEditor
                contentState={htmlContent}
                handleChange={handleEditorChange}
                onChange={handleEditorChange}
              />}
              {/* <Form.Control
                                as="textarea"
                                value={orgEmailText}
                                onChange={(e) => setorgEmailText(e.target.value)}
                            /> */}
            </Form.Group>
            <Form.Group className="mt-3" controlId="orgEmail">
              <Form.Label className="fw-bold">
                Email From (Email will go from <>@mygiving.io</>){" "}
              </Form.Label>
              <Form.Control
                as="input"
                value={orgEmail}
                onChange={(e) => setorgEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mt-3" controlId="orgWebsite">
              <Form.Label className="fw-bold">Website </Form.Label>
              <Form.Control
                as="input"
                value={orgWebsite}
                onChange={(e) => setorgWebsite(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mt-3" controlId="orgdonateURL">
              <Form.Label className="fw-bold">Donate URL </Form.Label>
              <Form.Control
                as="input"
                value={orgdonateURL}
                onChange={(e) => setorgdonateURL(e.target.value)}
              />
            </Form.Group>            
            <Form.Group className="mt-3" controlId="orgTaxID">
              <Form.Label className="fw-bold">Tax ID </Form.Label>
              <Form.Control
                as="input"
                value={orgTaxID}
                onChange={(e) => setorgTaxID(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mt-3" controlId="orgLocation">
              <Form.Label className="fw-bold">Location </Form.Label>
              <Form.Control
                as="input"
                value={orgLocation}
                onChange={(e) => setorgLocation(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mt-3" controlId="orgType">
              <Form.Label className="fw-bold">Organization Type </Form.Label>
              <Form.Control
                as="input"
                value={orgType}
                onChange={(e) => setorgType(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mt-3" controlId="orgKey">
              <Form.Label className="fw-bold">
                Wallet/Key Management{" "}
              </Form.Label>
              <Form.Switch
                onChange={onSwitchAction}
                id="custom-switch"
                label="MyGiving will manage the keys"
                checked={orgKey}
              />
            </Form.Group>
            <Form.Group className="mt-3" controlId="colorTheme">
              <Form.Label className="fw-bold">Color Theme</Form.Label>
              <SketchPicker
                color={orgColorTheme}
                onChangeComplete={handleChangeComplete}
              />
            </Form.Group>
            <Form.Group className="mt-3" controlId="bannerFile">
              <Form.Label className="fw-bold">Banner Image</Form.Label>
              {org.orgBannerImage && (
                <p>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={org.orgBannerImage}
                  >
                    {formatFilename(org.orgBannerImage)}
                  </a>
                </p>
              )}
              <Form.Control
                className="fileChoose"
                onChange={handleFileChangeBanner}
                type="file"
              />
            </Form.Group>
            <Form.Group className="mt-3" controlId="logoFile">
              <Form.Label className="fw-bold">Logo Image</Form.Label>
              {org.orgBannerLogo && (
                <p>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={org.orgBannerLogo}
                  >
                    {formatFilename(org.orgBannerLogo)}
                  </a>
                </p>
              )}
              <Form.Control
                className="fileChoose"
                onChange={handleFileChangeLogo}
                type="file"
              />
            </Form.Group>

            <LoaderButton
              block = "true"
              size="lg"
              type="submit"
              className="mt-3 mb-3"
              isLoading={isLoading}
              disabled={!validateForm()}
              style={{ backgroundColor: orgColorTheme }}
            >
              Save
            </LoaderButton>
          </Form>
        )}
      </div>
      {!isLoading2 ? <Footer /> : null}
    </div>
  );
}